<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" :rules="rules" label-width="100px">
      <div>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="SKU" prop="outboundCode">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style" prop="outboundCode">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Size" prop="outboundCode">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="类别">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="BU">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="总库存">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="在途库存">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="可用库存">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="锁定库存">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="SKU">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="待出库库存">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
      <!-- <span>
        <el-button type="primary" :disabled="show" :loading="releaseLoading">
          {{ $t('title.Shortagecompletion') }}</el-button>
        <el-button>{{ $t('page.export') }}</el-button>
      </span> -->
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" :rules="rules" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="逻辑仓" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="货主" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="value" label="逻辑仓库" align="center" />
      <el-table-column prop="value" label="货主" align="center" />
      <el-table-column prop="value" label="总库存" align="center" />
      <el-table-column prop="value" label="UPC" align="center" />
      <el-table-column prop="value" label="在途库存" align="center">
        <template slot-scope="scope">{{ scope.row.value }}</template>
      </el-table-column>
      <el-table-column prop="cartonVolume" label="在库库存" align="center">
        <template slot-scope="scope">{{ scope.row.value }}</template>
      </el-table-column>
      <el-table-column prop="cartonVolume" label="可用库存" align="center">
        <template slot-scope="scope">{{ scope.row.value }}</template>
      </el-table-column>
      <el-table-column prop="cartonVolume" label="承诺库存" align="center">
        <template slot-scope="scope">{{ scope.row.value }}</template>
      </el-table-column>
      <el-table-column prop="cartonVolume" label="锁定库存" align="center">
        <template slot-scope="scope">{{ scope.row.value }}</template>
      </el-table-column>
      <el-table-column prop="cartonVolume" label="待出库库存" align="center">
        <template slot-scope="scope">{{ scope.row.value }}</template>
      </el-table-column>
      <el-table-column prop="category1Code" label="不良品库存" align="center" />
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
// import PlatSelect from '@/components/ListSelection/PlatList'
// import PlatformSelect from '@/components/ListSelection/platform'

export default {
  data() {
    return {
      headlineProductsList: [],
      outboundForm: {
        outboundCode: '543656345',
        productType: '',
        productCode: '',
        inspectionWarehouse: '',
        targetWarehouseName: '',
        sourceCode: '',
        outboundStatus: '0', // 出库状态
        outboundType: '',
        outboundGoodsPlatformId: '',
        sendSellPlatformId: '',
        sendSiteCode: '',
        outPairs: 0, // 应出库数 新增不需要
        sendBoxNumber: 0, // 应出库箱数 新增不需要
        deliveryTime: '', // 预计发货时间
        remark: '',
        outboundWarehouseId: '' // 当前仓库id
      },
      tableLoading: false,
      tableData: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }]

      // rules: {
      //   depositType: [{ required: true, message: this.$t('title.selectdeliverytype'), trigger: 'change' }],
      //   receiveType: [{ required: true, message: this.$t('title.selectdeliverymethod'), trigger: 'change' }]
      // }
    }
  },
  computed: {
    // isWalmart() {
    //   const sendSiteCode = this.outboundForm.sendSiteCode
    //   return sendSiteCode === 'US-Walmart/109' || sendSiteCode === 'US-Walmart'
    // },
    // getLogisticsPlanParams() {
    //   const { inspectionWarehouse, productCode, targetWarehouseName, sendSiteCode } = this.outboundForm
    //   return Object.assign({}, { inspectionWarehouseCode: inspectionWarehouse, winitProductCode: productCode, destinationWarehouseCode: targetWarehouseName, siteCode: sendSiteCode.split('/')[0] })
    // }

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
